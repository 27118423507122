import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ConsentChangeListener, TrackingOptIn, TrackingOptInOptions } from '@mindscreen/consent-optin-ui/src/types';
import { consentOptIn } from '@mindscreen/consent-optin-ui/dist/ui';
import { OPTIN_STATUS } from '@mindscreen/consent-optin-ui/src/public';

export type ConsentStatus = Record<string, boolean>;

export interface IConsentContext {
    isGranted: (service: string) => boolean;
    grant: (service: string, granted: boolean) => void;
    showUi: () => void;
}

const ConsentContext = createContext<IConsentContext>({
    isGranted: () => false,
    grant: () => {/*noop*/},
    showUi: () => {/*noop*/},
});

interface IConsentContextProps extends React.PropsWithChildren {
    services: TrackingOptInOptions['services'];
    groups: TrackingOptInOptions['groups'];
    popupLabels: TrackingOptInOptions['popupLabels'];
}

const localStorageKey = '__consent';

const getStatusFromStorage = (): ConsentStatus => {
    const status: ConsentStatus = {};
    try {
        const storageItem = localStorage.getItem(localStorageKey);
        const storageData = JSON.parse(storageItem ?? '{}');
        if (typeof storageData === 'object' && !!storageData) {
            Object.keys(storageData).forEach(service => {
                if (typeof storageData[service] === 'boolean') {
                    status[service] = storageData[service];
                }
            });
        }
    } catch (e) {}
    return status;
};

const updateStatusInStorage = (status: ConsentStatus): void => {
    localStorage.setItem(localStorageKey, JSON.stringify(status));
};

export const ConsentProvider: React.FunctionComponent<IConsentContextProps> = props => {
    const [ status, setStatus ] = useState<ConsentStatus>(getStatusFromStorage());
    const consentUiRef = useRef<TrackingOptIn>();
    const version = Object.keys(props.services).join('');

    useEffect(() => {
        consentUiRef.current = consentOptIn({
            services: props.services,
            groups: props.groups,
            popupLabels: props.popupLabels,
            version,
        } as TrackingOptInOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isGranted = useCallback<IConsentContext['isGranted']>((service) => {
        return status[service] ?? props.services[service].defaultValue;
    }, [ status ]);

    const grant = useCallback<IConsentContext['grant']>((service, granted) => {
        setStatus(status => {
            const nextStatus = ({ ...status, [service]: granted });
            updateStatusInStorage(nextStatus);
            return nextStatus;
        });
    }, [ setStatus ]);

    const showUi = () => {
        consentUiRef.current?.showOptions();
    };

    useEffect(() => {
        const listeners: Record<string, ConsentChangeListener> = {};
        const storageStatus = getStatusFromStorage();
        Object.keys(props.services).forEach(serviceId => {
            listeners[serviceId] = (status: OPTIN_STATUS) => {
                grant(serviceId, status === OPTIN_STATUS.GRANTED);
            };
            consentUiRef.current?.on(serviceId, listeners[serviceId]);
            let serviceEnabled = false;
            switch (consentUiRef.current?.getServiceStatus(serviceId)) {
                case OPTIN_STATUS.GRANTED:
                    serviceEnabled = true;
                    break;
                case OPTIN_STATUS.UNSPECIFIED: {
                    serviceEnabled = storageStatus[serviceId]
                        ?? props.services[serviceId].defaultValue === true;
                }
                    break;
            }
            grant(serviceId, serviceEnabled);
        });
        return () => {
            Object.keys(props.services).forEach(serviceId => {
                consentUiRef.current?.off(serviceId, listeners[serviceId]);
            });

        };
    }, [ grant, props.services ]);

    return <ConsentContext.Provider value={{ grant, isGranted, showUi }} children={props.children} />;
};

interface IUseConsentResult extends IConsentContext {
}

export const useConsent = (): IUseConsentResult => {
    return useContext(ConsentContext);
};

type ServiceConsentResult = [ status: boolean, setStatus: (status: boolean) => void ];

interface IUseServiceConsentResult extends ServiceConsentResult {
}

export const useServiceConsent = (service: string): IUseServiceConsentResult => {
    const { grant, isGranted } = useContext(ConsentContext);

    const setStatus = useCallback((value: boolean) => {
        grant(service, value);
    }, [ service, grant ]);

    const granted = useMemo(() => {
        return isGranted(service);
    }, [ isGranted, service ]);

    return [ granted, setStatus ];
};
