import * as React from 'react';
import { PropsWithChildren } from 'react';
import { ConfigProvider } from './util/config';
import { ConsentProvider } from './util/consent';
import { ReportDataProvider } from './util/report-data';
import { consentOptions } from './components/consent';
import { Provider as MatomoProvider } from './matomo';
import { OverlayProvider } from '@react-aria/overlays';

const FontPreload = React.memo((props: { fontFile: string, id: string }): JSX.Element => {
    const { fontFile } = props;
    const parts = fontFile.split('.');
    const extension = parts[parts.length - 1].toLowerCase().split('?')[0];
    return (
        <link
            rel="preload"
            href={fontFile}
            as="font"
            type={`font/${ extension }`}
            crossOrigin=""
            id={props.id}
        />
    );
});

FontPreload.displayName = 'FontPreload';

export const App: React.FunctionComponent<PropsWithChildren<{}>> = (props) => {
    return (
        <OverlayProvider>
            <ConsentProvider {...consentOptions}>
                <MatomoProvider>
                    <ConfigProvider>
                        <ReportDataProvider>
                            {props.children}
                        </ReportDataProvider>
                    </ConfigProvider>
                </MatomoProvider>
            </ConsentProvider>
        </OverlayProvider>
    );
};

export const Head = (): JSX.Element => (
    <>
        <FontPreload fontFile="/fonts/Inter-Bold.woff2" id="fontbold"/>
        <FontPreload fontFile="/fonts/Inter-Regular.woff2" id="fontregular"/>
    </>
);

export default App;
