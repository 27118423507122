module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"axesCheck","start_url":"/","background_color":"#ffffff","theme_color":"#669C03","icon":"src/images/favicon.svg","icons":[{"src":"src/images/favicon.ico","sizes":"16x16","type":"image/vnd.microsoft.icon"},{"src":"src/images/favicon.svg","sizes":"16x16","type":"image/svg+xml"},{"src":"src/images/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/icon-128x128.png","sizes":"128x128","type":"image/png"},{"src":"src/images/icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f0acf8ffd61d057cb747f7ea5c7e16d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","de"],"defaultLanguage":"zz","generateDefaultLanguagePage":true,"i18nextOptions":{"interpolation":{"escapeValue":false}},"redirect":true,"fallbackLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
