import { ErrorResponse, SuccessResponse, TestSummary } from '../types/api';

const apiBaseUrl = process.env.GATSBY_BACKEND_URL ?? '';

type RequestResponse<T, E> = {
    success: true,
    data: T,
    error?: E | unknown,
    statusCode: number,
} | {
    success: false,
    data?: null,
    error: E,
    statusCode: number,
}

export const makeRequest = <T extends SuccessResponse<unknown>, E = ErrorResponse>(path: string, options?: RequestInit): Promise<RequestResponse<T, E>> =>
    fetch(apiBaseUrl + path, options)
        .then(async r => {
            const responseData = await r.text();
            let data;
            try {
                data = JSON.parse(responseData);
            } catch (e) {
                data = responseData;
            }
            const statusCode = r.status;
            if (r.ok) {
                return {
                    success: true,
                    data,
                    statusCode,
                };
            } else {
                return {
                    success: false,
                    error: data,
                    statusCode,
                };
            }
        });

export const getSummary = (jobId: string): Promise<TestSummary> =>
    makeRequest<TestSummary>(`/api/summary.php?job=${jobId}`)
        .then(({ success, data, error }) => {
            if (success) {
                return data;
            } else {
                throw error;
            }
        });

export const uploadFile = (file: File, language: string, token: string): Promise<TestSummary> => {
    const body = new FormData();
    body.append('token', token);
    body.append('file', file);
    body.append('language', language);
    return makeRequest<TestSummary>('/api/upload.php', {
        method: 'POST',
        body,
    })
        .then(({ success, data, error }) => {
            if (success) {
                return data;
            } else {
                throw error;
            }
        });
};
