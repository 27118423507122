import { TrackingOptInOptions } from '@mindscreen/consent-optin-ui/src/types';

export enum ServiceId {
    matomo = 'matomo',
    reCaptcha = 'reCaptcha',
}

export enum ServiceGroup {
    Statistics = 'statistics',
    Functional = 'functional',
}

export const consentOptions: Pick<TrackingOptInOptions, 'services' | 'groups' | 'popupLabels'> = ({
    services: {
        [ServiceId.matomo]: {
            group: ServiceGroup.Statistics,
            label: (`consent:services.${ServiceId.matomo}.label`),
            defaultValue: true,
        },
        [ServiceId.reCaptcha]: {
            group: ServiceGroup.Functional,
            label: (`consent:services.${ServiceId.reCaptcha}.label`),
            defaultValue: false,
        },
    },
    groups: {
        [ServiceGroup.Statistics]: {
            label: (`consent:groups.${ServiceGroup.Statistics}.label`),
        },
        [ServiceGroup.Functional]: {
            label: (`consent:groups.${ServiceGroup.Functional}.label`),
        },
    },
    popupLabels: {
        title: ('consent:popupLabels.title'),
        disabled: ('consent:popupLabels.disabled'),
        enabled: ('consent:popupLabels.enabled'),
        save: ('consent:popupLabels.save'),
        description: ('consent:popupLabels.description'),
    },
});
