import React, { createContext, useContext, useMemo, useState } from 'react';
import { TestSummary } from '../types/api';
import { noop } from './functions';

type ReportData = Map<string, TestSummary>;

interface IReportDataContext {
    getSummary: (jobId: string) => TestSummary | null;
    addSummary: (jobId: string, summary: TestSummary) => void;
    removeSummary: (jobId: string) => void;
    removeAll: () => void;
    knownSummaries: Set<string>;
}

const ReportDataContext = createContext<IReportDataContext>({
    getSummary: () => null,
    addSummary: noop,
    removeSummary: noop,
    removeAll: noop,
    knownSummaries: new Set<string>(),
});

export const ReportDataProvider: React.FunctionComponent<React.PropsWithChildren> = props => {
    const [ data, setData ] = useState<ReportData>(new Map());

    const [
        getSummary,
        knownSummaries,
    ] = useMemo<[
        IReportDataContext['getSummary'],
        IReportDataContext['knownSummaries']
    ]>(() => [
        (jobId) => {
            return data.has(jobId) ? data.get(jobId) ?? null : null;
        },
        new Set([ ...data.keys() ]),
    ], [ data ]);

    const [
        addSummary,
        removeSummary,
        removeAll,
    ] = useMemo<[
        IReportDataContext['addSummary'],
        IReportDataContext['removeSummary'],
        IReportDataContext['removeAll']
    ]>(() => [
        (jobId, summary) => {
            setData(data => {
                const next = new Map(data.entries());
                next.set(jobId, summary);
                return next;
            });
        },
        (jobId) => {
            setData(data => {
                const next = new Map(data.entries());
                next.delete(jobId);
                return next;
            });
        },
        () => {
            setData(new Map());
        },
    ], [ setData ]);

    return <ReportDataContext.Provider value={{ getSummary, addSummary, removeSummary, removeAll, knownSummaries }} {...props} />;
};

export const useReportData = (): IReportDataContext =>
    useContext(ReportDataContext);
