import React, { useEffect } from 'react';
import { createInstance, MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';

const siteId = Number(process.env.GATSBY_MATOMO_SITE_ID || '-1');

const instance = createInstance({
    urlBase: process.env.GATSBY_MATOMO_URL ?? '',
    siteId: siteId,
    disabled: siteId < 0,
    configurations: {
        disableCookies: true,
    },
});

export type SiteViewTrackingOptions = false | {
    url: string;
    title: string;
};

export const useTrackPageView = (options?: SiteViewTrackingOptions) => {
    const { trackPageView } = useMatomo();
    const url = options ? options.url : null;
    useEffect(() => {
        if (options === false) {
            return;
        }
        // special handling to never track "valid" result pages
        if (document?.location.pathname) {
            if (/\/result\/[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/.test(document.location.pathname) && !options?.url) {
                return;
            }
        }

        trackPageView({
            href: options?.url,
            documentTitle: options?.title,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ url ]);
};

export const Provider = (props: React.PropsWithChildren) =>
    <MatomoProvider value={instance} {...props} />;
