exports.components = {
  "component---src-components-layout-mdx-page-tsx-content-file-path-builds-axes-4-online-pac-content-pages-privacy-de-mdx": () => import("./../../../src/components/layout/MdxPage.tsx?__contentFilePath=/builds/axes4/online-pac/content/pages/privacy.de.mdx" /* webpackChunkName: "component---src-components-layout-mdx-page-tsx-content-file-path-builds-axes-4-online-pac-content-pages-privacy-de-mdx" */),
  "component---src-components-layout-mdx-page-tsx-content-file-path-builds-axes-4-online-pac-content-pages-privacy-en-mdx": () => import("./../../../src/components/layout/MdxPage.tsx?__contentFilePath=/builds/axes4/online-pac/content/pages/privacy.en.mdx" /* webpackChunkName: "component---src-components-layout-mdx-page-tsx-content-file-path-builds-axes-4-online-pac-content-pages-privacy-en-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-503-tsx": () => import("./../../../src/pages/503.tsx" /* webpackChunkName: "component---src-pages-503-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result/[...].tsx" /* webpackChunkName: "component---src-pages-result-tsx" */)
}

