import React, { createContext, useContext, useEffect, useState } from 'react';
import { makeRequest } from './api';
import { SuccessResponse } from '../types/api';

export interface AppConfig {
    /**
     * reCAPTCHA siteKey
     */
    recaptcha: string,
    uploadOptions: {
        /**
         * allowed file type
         */
        type: string,
        /**
         * file-size limit in bytes
         */
        size: number
    },
    maintenance: boolean,
}

const defaultConfig: AppConfig = {
    recaptcha: '',
    uploadOptions: {
        type: 'application/pdf',
        size: 0,
    },
    maintenance: false,
};

const ConfigContext = createContext<AppConfig|null>(null);

export const ConfigProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const [ config, setConfig ] = useState<AppConfig|null>(null);
    useEffect(() => {
        makeRequest<SuccessResponse<AppConfig>>('/api/config.php')
            .then(({ success, data }) => {
                if (success && data.body) {
                    setConfig(data.body);
                }
            });
    }, []);
    return <ConfigContext.Provider value={config} {...props}/>;
};

export const useConfig = (): AppConfig => {
    const context = useContext(ConfigContext);
    return context === null ? defaultConfig : context;
};
